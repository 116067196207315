html,
body {
  min-height: 100vh;
  overflow: auto;
  background-color: #F2F2F2;
}

a {
  text-decoration: none;
}

/*sub title*/
@font-face {
  font-family: sub-title;
  src: url('./fonts/OverLock/overlock-bold-webfont.woff');
}

/*title*/
@font-face {
  font-family: title;
  src: url('./fonts/OverLock/overlock-black-webfont.woff');
}

/*text*/
@font-face {
  font-family: text;
  src: url('./fonts/Varela Round/varelaround-regular-webfont.woff');
}
